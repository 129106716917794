
let 公共基础路径 = "/";
let 页面左上角平台名称 = "高校综合数据平台";
let 已选择子系统名称 = "系统概览";
let 页面底部版权单位名称 = "厦门飞瑞致远教育科技有限公司";
let icp备案号 = "闽ICP备2021017525号-2";

let 登录服务器地址 = 'http://www.udcpep.top:81/jdiep';//后台有两个系统一个是jdiep,一个是stats
// let 登录服务器地址 = 'http://www.udcpep.top:81/stats';//后台有两个系统一个是jdiep,一个是stats
// let 登录服务器地址 = 'http://www.udcpep.top:81/jdiep';//后台有两个系统一个是jdiep,一个是stats
// let 具体服务所在服务器地址 = 'http://172.27.128.14:83/api/';//后台有两个系统一个是jdiep,一个是stats
// let 具体服务所在服务器地址 = 'http://192.168.4.32:83/api/';//后台有两个系统一个是jdiep,一个是stats
let 具体服务所在服务器地址 = 登录服务器地址;//'http://119.91.238.7:83/api/';//后台有两个系统一个是jdiep,一个是stats
//let 具体服务所在服务器地址 = 'http://www.udcpep.top:81/stjb_serach/api/';//后台有两个系统一个是jdiep,一个是stats

let 已选择跟踪项目名称 = "已选跟踪项目";
let 已选跟踪项目对应数据库名=null;
let 已选跟踪项目数据频度=null;

// sessionStorage.setItem("页面左上角跟踪项目名称", 页面左上角跟踪项目名称);
let 平台主系统地址 = 'http://www.udcpep.top';
let 单位中文字符宽度 = 14;
export default {
    公共基础路径,页面左上角平台名称,页面底部版权单位名称,icp备案号,已选择子系统名称,已选择跟踪项目名称,
    登录服务器地址,具体服务所在服务器地址,已选跟踪项目对应数据库名,已选跟踪项目数据频度,平台主系统地址,
    单位中文字符宽度
}

